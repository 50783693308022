<template>
	<nav class="w-100 bg-primary menubar">
		<ul>
			<li
				v-if="
					divisionFront.competition_type === 'ELIMINACION DIRECTA' ||
						divisionFront.competition_type === 'ELIMINACION DIRECTA ESPECIAL'
				"
			>
				<router-link
					:to="{
						name: 'DivisionLiguilla',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $t('home') }}</router-link
				>
			</li>
			<li v-else>
				<router-link
					:to="{
						name: 'DivisionHome',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryDivision === 'home' }"
					>{{ $t('home') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionUpcomingMatches',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryDivision === 'matches' }"
					>{{ $t('matches') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionBests',
						params: { lang: lang, console: console, divisionSlug: this.slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryDivision === 'bests' }"
					>{{ $t('theBest') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionYellowCards',
						params: { lang: lang, console: console, divisionSlug: this.slug }
					}"
					exact
					:class="{ 'router-link-active': menuPrimaryDivision === 'cards' }"
					>{{ $tc('card', 2) | toCapitalize }}</router-link
				>
			</li>
			<li v-if="divisionFront.current_team_week">
				<router-link
					:to="{
						name: 'DivisionTeamWeek',
						params: {
							lang: lang,
							console: console,
							divisionSlug: this.slug,
							code: this.divisionFront.current_team_week.name
						}
					}"
					exact
					>{{ $t('teamWeek') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionBanned',
						params: { lang: lang, console: console, divisionSlug: this.slug }
					}"
					exact
					>{{ $t('banned') }}</router-link
				>
			</li>
		</ul>
		<ul v-if="menuPrimaryDivision === 'home'">
			<li
				v-if="
					divisionFront.competition_type === 'ELIMINACION DIRECTA' ||
						divisionFront.competition_type === 'ELIMINACION DIRECTA ESPECIAL'
				"
			>
				<router-link
					:to="{
						name: 'DivisionLiguilla',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>Liguilla</router-link
				>
			</li>
			<li v-else>
				<router-link
					:to="{
						name: 'DivisionHome',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $t('table') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionNotices',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $t('notices') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionTeams',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $t('teams') }}</router-link
				>
			</li>
		</ul>
		<ul v-if="menuPrimaryDivision === 'bests'">
			<li>
				<router-link
					:to="{
						name: 'DivisionBests',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $t('theBest') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionBestGks',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>Gk</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionBestAssists',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $t('assistances') }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionBestScorers',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $tc('scorer', 2) | toCapitalize }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionBestMvps',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>Mvp</router-link
				>
			</li>
		</ul>
		<ul v-if="menuPrimaryDivision === 'cards'">
			<li>
				<router-link
					:to="{
						name: 'DivisionYellowCards',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $tc('yellowCard', 2) | toCapitalize }}</router-link
				>
			</li>
			<li>
				<router-link
					:to="{
						name: 'DivisionRedCards',
						params: { lang: lang, console: console, divisionSlug: slug }
					}"
					exact
					>{{ $tc('redCard', 2) | toCapitalize }}</router-link
				>
			</li>
		</ul>
	</nav>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				slug: '*'
			}
		},
		computed: {
			...mapGetters(['lang', 'console', 'menuPrimaryDivision', 'divisionFront'])
		},
		mounted() {
			this.slug = this.$route.params.divisionSlug
		}
	}
</script>
