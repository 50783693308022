var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"w-100 bg-primary menubar"},[_c('ul',[(
				_vm.divisionFront.competition_type === 'ELIMINACION DIRECTA' ||
					_vm.divisionFront.competition_type === 'ELIMINACION DIRECTA ESPECIAL'
			)?_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionLiguilla',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('home')))])],1):_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryDivision === 'home' },attrs:{"to":{
					name: 'DivisionHome',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('home')))])],1),_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryDivision === 'matches' },attrs:{"to":{
					name: 'DivisionUpcomingMatches',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('matches')))])],1),_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryDivision === 'bests' },attrs:{"to":{
					name: 'DivisionBests',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: this.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('theBest')))])],1),_c('li',[_c('router-link',{class:{ 'router-link-active': _vm.menuPrimaryDivision === 'cards' },attrs:{"to":{
					name: 'DivisionYellowCards',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: this.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.$tc('card', 2))))])],1),(_vm.divisionFront.current_team_week)?_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionTeamWeek',
					params: {
						lang: _vm.lang,
						console: _vm.console,
						divisionSlug: this.slug,
						code: this.divisionFront.current_team_week.name
					}
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('teamWeek')))])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionBanned',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: this.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('banned')))])],1)]),(_vm.menuPrimaryDivision === 'home')?_c('ul',[(
				_vm.divisionFront.competition_type === 'ELIMINACION DIRECTA' ||
					_vm.divisionFront.competition_type === 'ELIMINACION DIRECTA ESPECIAL'
			)?_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionLiguilla',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v("Liguilla")])],1):_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionHome',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('table')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionNotices',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('notices')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionTeams',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('teams')))])],1)]):_vm._e(),(_vm.menuPrimaryDivision === 'bests')?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionBests',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('theBest')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionBestGks',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v("Gk")])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionBestAssists',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm.$t('assistances')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionBestScorers',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.$tc('scorer', 2))))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionBestMvps',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v("Mvp")])],1)]):_vm._e(),(_vm.menuPrimaryDivision === 'cards')?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionYellowCards',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.$tc('yellowCard', 2))))])],1),_c('li',[_c('router-link',{attrs:{"to":{
					name: 'DivisionRedCards',
					params: { lang: _vm.lang, console: _vm.console, divisionSlug: _vm.slug }
				},"exact":""}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.$tc('redCard', 2))))])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }